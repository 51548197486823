import React, { useState, useRef, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Spinner, Typography } from 'bluematter';

const CheckGraphPermissions = ({ children }) => {
  const [shouldRetry, setShouldRetry] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [retryCount, setRetryCount] = useState(0);
  const retryInterval = useRef(null);

  const findUserQuery = gql`
    query FindUserByFirstName($firstName: String!) {
      users
        @rest(
          type: "User"
          path: "/users?$filter=displayName eq '.'"
          scopes: "login"
        ) {
        id
      }
    }
  `;

  const { data, error, loading, refetch } = useQuery(findUserQuery, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    onError: (error) => {
      if (shouldRetry) {
        retryInterval.current = setInterval(() => {
          setRetryCount((prevCount) => {
            if (prevCount < 10) {
              console.log('Retrying query...', prevCount);
              refetch();
            } else {
              setShouldRetry(false);
            }
            return prevCount + 1;
          });
        }, 3000);
      } else {
        setShouldRetry(false);
        setRetryCount(0);
      }
    },
  });

  useEffect(() => {
    if (data) {
      setShouldRetry(false);
    }
  }, [shouldRetry, data]);

  if (loading || shouldRetry) {
    return <Spinner isFullscreen={true} isFixed={true}></Spinner>;
  } else if (error) {
    clearInterval(retryInterval.current);
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div>
          <Typography variant="h3">Error during setup.</Typography>
          <Typography>
            There was an error when getting response from Microsoft Graph.
            Please try again later. <br /> If the error persists, contact
            support.
          </Typography>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default CheckGraphPermissions;
