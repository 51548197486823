import { useQuery } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { Avatar, Button, Typography } from 'bluematter';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import GET_CURRENT_USER from '../../../graphql/queries/GetCurrentUser';
import Authorize from '../../auth/authorize/Authorize';
import { ROLES } from '../../auth/roles';
import SidebarHeader from '../header/Header';
import Sidebar from '../Sidebar';
import './ProfileSidebar.css';

const ProfileSidebar = ({ className = '', isSidebarToggleOn }) => {
  const { client, data } = useQuery(GET_CURRENT_USER);
  const { instance } = useMsal();

  return (
    <Authorize roles={[ROLES.USER]}>
      {({ access }) =>
        access && (
          <Sidebar className={className} isToggleOn={isSidebarToggleOn}>
            <>
              <SidebarHeader>
                <FormattedMessage id="nav.profile" defaultMessage="Profile" />
              </SidebarHeader>
              <div className="user-sidebar">
                {data?.currentUser?.firstName && (
                  <div className="user-sidebar-header">
                    <Avatar
                      name={`${data.currentUser.firstName}${
                        data?.currentUser?.lastName
                          ? ' ' + data.currentUser.lastName
                          : ''
                      }`}
                    />
                    <div className="user-account">
                      <Typography className="user-name" noMargin>
                        {`${data.currentUser.firstName}${
                          data?.currentUser?.lastName
                            ? ' ' + data.currentUser.lastName
                            : ''
                        }`}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="user-settings-container">
                  <ul className="user-settings">
                    <li className="user-setting">
                      <Button
                        onClick={() => {
                          instance
                            .logoutRedirect()
                            .then(() =>
                              // Reset Apollo Cache
                              client.resetStore()
                            )
                            .catch((e) => {
                              console.error(e);
                            });
                        }}>
                        <FormattedMessage
                          id="nav.auth.signOut"
                          defaultMessage="Sign out"
                        />
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          </Sidebar>
        )
      }
    </Authorize>
  );
};

ProfileSidebar.propTypes = {
  isSidebarToggleOn: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default ProfileSidebar;
