import { gql } from '@apollo/client';
import { ROLES } from '../../components/auth/roles';

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser @client {
      id
      loggedIn
      firstName
      lastName
      roles
    }
  }
`;

export default GET_CURRENT_USER;

export const getCurrentUserMock = {
  data: {
    currentUser: {
      id: '07b03793-6a70-4aff-a056-6cb0c8e57200',
      loggedIn: true,
      firstName: 'John',
      lastName: 'Doe',
      roles: [ROLES.OWNER, ROLES.USER],
      __typename: 'CurrentUser',
    },
  },
};
