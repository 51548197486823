import { onError } from '@apollo/client/link/error';
import { appInsights } from '../Root';

/**
 * @name errorLink
 * @description GraphQl error link with application insights
 */
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  try {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        throw new Error(
          `[GraphQL error]: Message: ${message}${
            locations ? `, Location: ${locations}` : ''
          }${path ? `, Path:${path}` : ''}`
        );
      });
    }
    if (networkError) {
      throw new Error(`[Network error]: ${networkError}`);
    }
  } catch (error) {
    console.error(error);
    appInsights.trackException({ exception: error });
  }
});
