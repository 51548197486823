import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import HostSettings from '../HostSettings';
import { appInsights } from '../Root';
import msal from '../utils/msal';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

/**
 * @name getScopesForQuery
 * @description get scopes from main definition of query
 * depending of if definition selection set selections contains rest directives
 *
 * @param {object} query
 * @returns scopes
 */
const getScopesForQuery = (query) => {
  const definition = getMainDefinition(query);

  const selections = definition.selectionSet.selections.filter(
    (item) => item.directives.length && item
  );

  // if we have more selections
  // and if selections do not have directives
  // return userSettings.defaultScopes
  if (!selections.length) {
    return HostSettings.defaultScopes;
  } else {
    // loop through selections and handle rest directives
    for (const selection of selections) {
      const restDirective = selection.directives.find(
        (directive) => directive.name.value === 'rest'
      );

      // if rest directive try to return scope from arguments
      // else return HostSettings.defaultScopes
      if (restDirective) {
        try {
          // find scope argument
          const scopesArgument = restDirective.arguments.find(
            (argument) => argument.name.value === 'scopes'
          );

          if (scopesArgument) {
            const scope = scopesArgument.value.value;
            if (scope) {
              if (scope === 'default') {
                return HostSettings.defaultScopes;
              } else if (scope === 'login') {
                return HostSettings.loginScopes;
              }

              throw new Error(
                `Invalid scope '${scope} in AADI. Cannot getAccessToken'.`
              );
            }

            if (scopesArgument.value.values) {
              return scopesArgument.value.values.map((value) => value.value);
            }
          } else {
            throw new Error(
              'Did not find any scopes in rest directive argument'
            );
          }
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            console.error(`${error.name}: ${error.message}`);
          }

          appInsights.trackException({ exception: error });
        }
      }
    }

    return HostSettings.defaultScopes;
  }
};

const interactionRequiredErrorCodes = [
  'consent_required',
  'interaction_required',
  'login_required',
];

const errorRequiresInteraction = (error) =>
  error instanceof InteractionRequiredAuthError &&
  interactionRequiredErrorCodes.includes(error.errorCode);

const errorIsBrowserAuthError = (error) => error instanceof BrowserAuthError;

/**
 * @name authLink
 * @description Apollo client auth.
 */
export const authLink = setContext(async (request, previousContext) => {
  const scopes = getScopesForQuery(request.query);
  if (!scopes) {
    return previousContext;
  }

  try {
    const activeAccount = msal.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msal.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      throw new Error('User is not signed in');
    }

    const accessTokenResponse = await msal.acquireTokenSilent({
      scopes,
      account: activeAccount || accounts[0],
    });
    const token = accessTokenResponse.accessToken;

    localStorage.setItem('msToken', token);
    localStorage.setItem('shouldRefetchToken', true); // if we have a token, in case of an error, try to get it again

    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
  } catch (error) {
    appInsights.trackException({ exception: error });

    if (process.env.NODE_ENV === 'development') {
      console.log(`${error.name}: ${error.message}`);
    }

    if (errorRequiresInteraction(error)) {
      await msal.acquireTokenRedirect({ scopes });
    }

    if (
      errorIsBrowserAuthError(error) &&
      localStorage.getItem('shouldRefetchToken')
    ) {
      localStorage.removeItem('shouldRefetchToken');
      appInsights.trackTrace(
        {
          message: 'Failed to get access token',
          severityLevel: SeverityLevel.Error,
        },
        {
          error: error.message,
        }
      );
      await msal.acquireTokenRedirect({ scopes });
    }
  }
});
