import { useMsal } from '@azure/msal-react';
import React from 'react';
import SignInComponent from './components/auth/signIn/SignInComponent';
import HostSettings from './HostSettings';

export const LogIn = () => {
  const { instance } = useMsal();

  return (
    <SignInComponent
      onClick={() =>
        instance
          .loginRedirect({ scopes: HostSettings.loginScopes })
          .catch((e) => {
            console.error(e);
          })
      }
    />
  );
};
