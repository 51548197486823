import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Authorize from '../../../components/auth/authorize/Authorize';
import { ROLES } from '../../../components/auth/roles';

const Redirects = () => {
  return (
    <Switch>
      <Authorize roles={[ROLES.OWNER]}>
        <Redirect id="admin-sync" from="/admin" to="/admin/sync" />
      </Authorize>
    </Switch>
  );
};

export default Redirects;
