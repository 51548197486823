import { ApolloClient, ApolloLink, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { getMainDefinition } from '@apollo/client/utilities';
import GET_CURRENT_USER from '../graphql/queries/GetCurrentUser';
import HostSettings from '../HostSettings';
import { authLink } from './authLink';
import { defaults } from './defaults';
import { diagnosticsLink } from './diagnosticsLink';
import { errorLink } from './errorLink';
import { httpLink } from './httpLink';
import { resolvers } from './resolvers';
import { restLink } from './restLink';
import { subscriptionLink } from './subscriptionLink';

const cache = new InMemoryCache({
  dataIdFromObject: (object) => object.id,
});

cache.writeQuery({
  query: GET_CURRENT_USER,
  data: defaults,
});

const mainLink = ApolloLink.from([
  diagnosticsLink,
  authLink,
  errorLink,
  restLink('https://graph.microsoft.com/v1.0', HostSettings),
  httpLink(HostSettings.graphHttpAddress),
]);

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  /**
   * Hybrid WebSocket transport,
   * that handles only subscriptions over WebSocket,
   * and a regular HTTP network interface */
  subscriptionLink(HostSettings.graphWsAddress),
  mainLink
);

export default new ApolloClient({
  cache,
  link,
  resolvers,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
