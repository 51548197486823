import React from 'react';
import { FormattedMessage } from 'react-intl';
import Main from '../../components/main/Main';

const PageNotFound = ({ location }) => {
  return (
    <>
      <Main style={{ flex: '100%', height: '100%' }}>
        <h3 style={{ textAlign: 'center', marginTop: 0 }}>
          <FormattedMessage
            id="pageNotFound"
            defaultMessage="No match for {code}"
            values={{
              code: <code>{location.pathname}</code>,
            }}
          />
        </h3>
      </Main>
    </>
  );
};

export default PageNotFound;
