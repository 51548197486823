import { useQuery } from '@apollo/client';
import GET_CURRENT_USER from '../../../graphql/queries/GetCurrentUser';

/**
 * @name Authorize
 * @description Authorize determines whether you are authorized to
 * access the resource/component i.e. verifying whether access is
 * allowed or not.
 * Authorize takes prop roles as array and a function as a child
 * and provides the properties access and user that can be used to
 * render different UI depending on the state of Authorize.
 * To handle custom rejection pass a function to the prop onReject.
 *
 * @param {array} roles array of roles that are given access
 * @param {(React.ReactNode|func)} children takes a function and calls it instead of implementing its own logic
 * @param {func} [onReject=()=>{}] takes a function. Can be used to implement custom logic e.g. `onReject = (access) => {if (!access) history.push('/');}`
 *
 * @example
 * // Using the render/children prop pattern
 * <Authorize roles={[ROLES.USER]}>
 *  {({ access, user }) =>
 *    !access && (<p>You do not have access</p>)
 *    access && (<p>{`${user.firstName} ${user.lastName}`}</p>)
 *  }
 * </Authorize>
 *
 * // Or a component as children
 * <Authorize roles={[ROLES.USER]}>
 *  <Component />
 * </Authorize>
 */
const Authorize = ({ roles, children, onReject = () => {} }) => {
  const { loading, data } = useQuery(GET_CURRENT_USER);

  if (loading) return null;

  const access = data?.currentUser?.roles?.some((role) => roles.includes(role));

  if (onReject) onReject(access, data?.currentUser);

  // if render prop children
  if (typeof children === 'function')
    return children({ access, user: data?.currentUser });

  // else if has access return children else return null
  return access ? children : null;
};

export default Authorize;
