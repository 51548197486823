import { Svg, Typography } from 'bluematter';
import React from 'react';
import './SignInComponent.css';
import SsoProvider from './ssoProvider/SsoProvider';

const SignInComponent = ({ onClick }) => {
  return (
    <main className="sign-in-main">
      <section className="sign-in">
        <header className="sign-in-header">
          <Typography variant="srOnly" component="h1">
            AskCody
          </Typography>
          <AskCodyLogo />
        </header>
        <div className="sign-in-body">
          <SsoProvider onClick={onClick} text="Sign-in with Microsoft">
            <IconMicrosoft />
          </SsoProvider>
        </div>
        <footer className="sign-in-footer">
          <Typography variant="caption" noMargin>
            <a
              href="https://www.askcody.com/terms-and-conditions"
              target="_blank"
              rel="noreferrer">
              Terms and conditions
            </a>
          </Typography>
        </footer>
      </section>
    </main>
  );
};

const IconMicrosoft = () => (
  <Svg className="ms-logo icon">
    <title>ms-logo</title>
    <path fill="#f25022" d="M0 15.158h15.158v-15.158h-15.158v15.158z" />
    <path fill="#00a4ef" d="M0 32h15.158v-15.158h-15.158v15.158z" />
    <path fill="#7fba00" d="M16.842 15.158h15.158v-15.158h-15.158v15.158z" />
    <path fill="#ffb900" d="M16.842 32h15.158v-15.158h-15.158v15.158z" />
  </Svg>
);

const AskCodyLogo = () => (
  <svg viewBox="0 0 949 208" className="askcody-logo">
    <defs>
      <path id="a" d="M.06.716h177.476v158.679H.06z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 2)">
        <path
          fill="#00287A"
          d="M177.536 159.395L109.926.716H67.611l41.967 98.495H67.611L53.214 133h70.761l11.247 26.395h42.314"
        />
        <path
          fill="#0080FF"
          d="M67.611.716h42.314l-67.61 158.679H0L67.611.716"
        />
      </g>
      <path
        fill="#0054FF"
        d="M109.926 2.717L88.769 52.373 67.612 2.717h42.314"
      />
      <path
        fill="#00287A"
        d="M406.797 161.395h-42.314l-27.826-44.157-10.119 9.89v34.267h-36.794V2.716h36.794v82.787l34.496-37.484h42.313l-41.392 42.545 44.842 70.831M530.919 114.687c-7.33 5.767-20.363 13.863-35.798 13.863-29.294 0-44.829-24.191-44.829-47.715 0-22.859 14.425-45.273 44.829-45.273 14.111 0 26.473 6.679 33.519 11.46l17.767-30.154C537.611 10.229 519.82.053 493.346.053c-47.714 0-83.223 35.509-83.223 81.67 0 47.049 33.511 82.335 83.223 82.335 29.002 0 48.669-12.999 57.893-20.833l-20.32-28.538M193.581 123.693s14.986 12.016 31.463 12.016c5.789 0 14.678-2.426 14.678-7.667 0-12.78-57.762-8.224-57.762-49.03 0-21.598 20.707-34.957 47.203-34.957 26.719 0 40.301 13.137 40.301 13.137L257.3 82.589s-13.379-11.354-29.41-11.354c-7.888 0-12.716 3.468-12.716 8.175 0 13.506 57.891 6.027 57.891 48.364 0 19.594-18.965 36.293-48.578 36.293-28.722 0-45.867-16.922-45.867-16.922l14.961-23.452M750.572 132.322c11.541 0 22.415-8.433 22.415-28.185 0-13.759-7.324-27.297-22.415-27.297-11.984 0-22.414 9.765-22.414 27.297 0 17.089 9.099 28.185 22.414 28.185zm-8.877-87.883c23.081 0 30.182 9.099 30.182 9.099V2.716h38.394v158.679h-35.952v-13.982s-9.765 16.645-33.29 16.645c-31.07 0-51.487-24.412-51.487-59.921 0-36.174 21.749-59.698 52.153-59.698zM624.336 130.329c-2.87 1.347-6.004 1.993-9.191 1.993-3.058 0-5.89-.592-8.445-1.7-9.399-3.44-16.36-12.716-16.36-26.041 0-17.31 11.448-28.185 24.805-28.185 13.356 0 24.805 10.875 24.805 28.185 0 12.969-6.596 22.097-15.614 25.748zm-9.403-85.89c-34.133 0-61.694 24.19-61.694 60.142 0 35.731 27.561 59.477 61.906 59.477s61.906-23.746 61.906-59.477c0-35.952-27.561-60.142-62.118-60.142zM910.368 47.102l-25.081 59.125-25.874-59.125h-39.736l46.052 105.231L842.116 208h38.64l68.251-160.898h-38.639"
      />
    </g>
  </svg>
);

export default SignInComponent;
