import React from 'react';
import './Sidebar.css';

interface Props {
  isToggleOn?: boolean;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

/**
 * Generic sidebar wrapper component that accepts
 * any number of children and can be toggled open/closed
 *
 * @returns Sidebar wrapper component
 */
const Sidebar = ({ isToggleOn = false, className = '', children }: Props) => {
  return (
    <aside className={`sidebar ${isToggleOn ? 'is-open' : ''} ${className}`}>
      {children}
    </aside>
  );
};

export default Sidebar;
