import { Typography } from 'bluematter';
import PropTypes from 'prop-types';
import React from 'react';
import './SsoProvider.css';

const SsoProvider = (props) => {
  return (
    <button className="sso-provider" onClick={props.onClick}>
      {props.children}
      <Typography variant="caption" noMargin>
        {props.text}
      </Typography>
    </button>
  );
};

SsoProvider.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  text: PropTypes.string,
};

export default SsoProvider;
