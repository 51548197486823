import 'core-js/stable';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/nb';
import 'moment/locale/nl';
import 'moment/locale/sv';
import React from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import Root from './Root';

ReactDOM.render(<Root />, document.getElementById('root'));

registerServiceWorker();
