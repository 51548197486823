export const validate = {
  /**
   * Matches two or more words
   */
  fullName: /(\w+\s?\b){2,}/,
  /**
   * Email Validation as per RFC2822 standards. - Straight from .net helpfiles :) by Tripleaxis
   */
  email: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  /**
   * Phone number matching, by Alex Snet
   */
  phone: /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
  /**
   * Matches any latin alphanumeric characters, hyphen, dash and underscore
   */
  alphaNumLatin: /^([\w-–.\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]+)$/,
  /** UUID/v4 */
  uuid: /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
};
