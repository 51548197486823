import { Accordion } from 'bluematter';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Authorize from '../../../components/auth/authorize/Authorize';
import { ROLES } from '../../../components/auth/roles';
import Nav from '../../../components/nav/Nav';
import NavItem from '../../../components/nav/navItem/NavItem';
import Sidebar from '../../../components/sidebar/Sidebar';

const AdminSidebar = () => {
  return (
    <Sidebar className="sidebar--left">
      <Authorize roles={[ROLES.OWNER]}>
        <Accordion title="Azure AD">
          <Nav>
            <FormattedMessage id="nav.sync" defaultMessage="Sync">
              {(txt) => (
                <NavItem path="/admin/sync" icon="ios-sync" name={txt[0]} />
              )}
            </FormattedMessage>
            <FormattedMessage id="nav.logs" defaultMessage="Logs">
              {(txt) => (
                <NavItem
                  path="/admin/azure-ad-sync-log"
                  icon="ios-list"
                  name={txt[0]}
                  exact={false}
                />
              )}
            </FormattedMessage>
            <FormattedMessage
              id="nav.troubleshooting"
              defaultMessage="Troubleshooting">
              {(txt) => (
                <NavItem
                  path="/admin/azure-ad-sync-troubleshooting"
                  icon="settings"
                  name={txt[0]}
                />
              )}
            </FormattedMessage>
          </Nav>
        </Accordion>
      </Authorize>
    </Sidebar>
  );
};

export default AdminSidebar;
