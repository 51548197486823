import React from 'react';
import './Main.css';

interface Props {
  className?: string;
  style?: {};
  isDrawerToggleOn?: boolean;
  children: React.ReactNode;
}

/**
 * @name Main
 * @description main wrapper component
 */
const Main = ({ className, style, isDrawerToggleOn, children }: Props) => {
  return (
    <main className={`main ${className}`} style={{ ...style }}>
      <div
        id="main-portal"
        className={`${isDrawerToggleOn ? ' sidebar--right is-open' : ''}`}
      />
      <div
        className={`main-container${
          isDrawerToggleOn ? ' sidebar--right is-open' : ''
        }`}>
        {children}
      </div>
    </main>
  );
};

export default Main;
