import { RestLink } from 'apollo-link-rest';

/**
 * @name restLink
 * @description Apollo rest link
 */
export const restLink = (uri, hostSettings) =>
  new RestLink({
    uri: uri,
    endpoints: { aadi: hostSettings.aadiBaseUrl },
    headers: {
      'Content-Type': 'application/json',
    },
  });
