import { Typography } from 'bluematter';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavItem.css';

interface Props {
  name: string;
  path: string;
  exact?: boolean;
  onClick?(e: React.MouseEvent): void;
  icon?: string;
  count?: number;
  className?: string;
}

const NavItem = ({
  exact = true,
  path,
  onClick,
  icon,
  name,
  count,
  className,
}: Props) => {
  return (
    <li className="nav-item-container">
      <NavLink
        exact={exact}
        to={path}
        onClick={onClick}
        className={`nav-item ${className || ''}`}
        activeClassName="is-selected">
        {icon && (
          <svg className={`icon icon-${icon}`}>
            <use xlinkHref={`#icon-${icon}`} />
          </svg>
        )}
        <Typography noMargin>{name}</Typography>
        {count && Number.isInteger(count) && (
          <span className="count">{count}</span>
        )}
      </NavLink>
    </li>
  );
};

export default NavItem;
