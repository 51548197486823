export const browserLanguage =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

export const browserLanguageCode = browserLanguage.split(/[-_]/)[0];

export function getLanguageCode(language) {
  return language.split(/[-_]/)[0];
}

export function formatLanguageCode(language) {
  const arr = Object.keys(language)[0].split(/[-_]/);

  if (arr.length === 2) return `${arr[0]}-${arr[1].toUpperCase()}`;

  return arr[0];
}

export function formatPreferredLanguage(language) {
  return language.replace('-', '_').toLowerCase();
}
