import { gql, useMutation, useQuery } from '@apollo/client';
import { useIsAuthenticated } from '@azure/msal-react';
import { Alert, Spinner, Typography } from 'bluematter';
import React, { useEffect, useRef } from 'react';
import { LogIn } from './LogIn';
import { appInsights } from './Root';

export const GET_ME = gql`
  query GetMe {
    me {
      id
      customer {
        id
      }
      firstName
      lastName
      email
      roles
    }
  }
`;

export const LOGIN = gql`
  mutation LogUserIn($acUser: String!) {
    logUserIn(acUser: $acUser) @client {
      id
      loggedIn
      firstName
      lastName
      roles
    }
  }
`;

const AuthRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  const { loading: userLoading, error: userError, data } = useQuery(GET_ME, {
    // If we don't have an msalAccount or the component is unmounted we skip the query
    skip: !isAuthenticated,
  });

  const [login, { loading: loginLoading, error: loginError }] = useMutation(
    LOGIN
  );

  const calledOnce = useRef(false);
  useEffect(() => {
    let unmount = false;

    if (calledOnce.current) {
      return;
    }

    if (!unmount && !userLoading && data?.me && isAuthenticated) {
      const acUser = data.me;
      // Set the authenticated user id, account id, and store in cookie
      appInsights.setAuthenticatedUserContext(
        acUser.id,
        acUser.customer.id,
        true
      );

      login({
        variables: {
          // Add ROLE_USER. This is a special role that all authenticated users
          // have, and various access checks rely on its existence.
          // Apollo v3 is immutable so we use the spread operator
          acUser: { ...acUser, roles: [...acUser.roles, 'ROLE_USER'] },
        },
      });

      calledOnce.current = true;
    }

    return () => {
      unmount = true;
    };
  }, [data, isAuthenticated, login, userLoading]);

  if (userLoading || loginLoading) {
    return <Spinner isFullscreen isFixed theme="light" />;
  }

  if (userError) {
    console.error(userError);
    appInsights.trackException(userError);

    return (
      <Alert type="warning">
        <Typography noMargin>{userError?.message}</Typography>
      </Alert>
    );
  }

  if (loginError) {
    console.error(loginError);
    appInsights.trackException(loginError);

    return (
      <Alert type="warning">
        <Typography noMargin>{loginError?.message}</Typography>
      </Alert>
    );
  }

  return (
    <>
      {!userLoading && !loginLoading && data?.me && isAuthenticated ? (
        children
      ) : (
        <LogIn />
      )}
    </>
  );
};

export default AuthRoute;
