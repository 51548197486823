import { Button, Typography } from 'bluematter';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Route } from 'react-router-dom';
import Authorize from '../auth/authorize/Authorize';
import { ROLES } from '../auth/roles';
import './Navbar.css';

const Navbar = ({ handleProfileSidebarToggle, isDrawerToggleOn }) => {
  return (
    <header className="navbar">
      <Route
        path="/admin"
        render={() => (
          <Link to="/admin" className="navbar-brand">
            <Typography
              color="primary"
              variant="h4"
              component="h1"
              isUnselectable
              noMargin
              className="navbar-brand-title">
              <FormattedMessage id="admin.title" defaultMessage="Admin" />
            </Typography>
          </Link>
        )}
      />

      <div className="navbar-drawer-control">
        <Authorize roles={[ROLES.USER]}>
          <Button
            className={`navbar-drawer-control-button profile-control${
              isDrawerToggleOn ? ' is-active' : ''
            }`}
            onClick={handleProfileSidebarToggle}
            aria-label="toggle profile"
            aria-pressed={isDrawerToggleOn}
            icon={
              <svg className="icon icon-user">
                <use xlinkHref="#icon-user" />
              </svg>
            }
          />
        </Authorize>
      </div>
    </header>
  );
};

export default Navbar;
