import { ApolloProvider } from '@apollo/client';
import { MsalProvider } from '@azure/msal-react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Base } from 'bluematter';
import { createBrowserHistory } from 'history';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import apolloClient from './apollo/apolloClient';
import App from './App';
import AuthRoute from './AuthRoute';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import HostSettings from './HostSettings';
import msal from './utils/msal';
import pageTitle from './utils/string/pageTitle/pageTitle';

// History object
const history = createBrowserHistory();

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: HostSettings.instrumentationKey,
    /* ...Other Configuration Options... */
  },
});

appInsights.loadAppInsights();

// Initialize telemetry with cloud role name
appInsights.addTelemetryInitializer((envelope) => {
  if (envelope.tags) {
    envelope.tags['ai.cloud.role'] = HostSettings.aiCloudRole;
  }
});

// Listen to history and track page view name and url on route change
if (history) {
  history.listen(({ pathname }) => {
    appInsights.trackPageView({
      name: pageTitle(pathname, 'Portal'),
      uri: pathname,
    });
  });
}

const Authorize = () => <div />;

const AppProviders = ({ children }) => {
  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <MsalProvider instance={msal}>{children}</MsalProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export const Root = () => {
  return (
    <AppProviders>
      <Router history={history}>
        <Switch>
          <Base>
            <Route exact path="/authorize" component={Authorize} />
            <AuthRoute>
              <Route path="/" component={App} />
            </AuthRoute>
          </Base>
        </Switch>
      </Router>
    </AppProviders>
  );
};

export default Root;
