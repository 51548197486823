import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { appInsights } from '../Root';

/**
 * @name subscriptionLink
 * @description GraphQL WebSocket subscriptions transport link
 * See https://github.com/apollographql/subscriptions-transport-ws#subscriptionclient
 * and https://github.com/apollographql/subscriptions-transport-ws/issues/293
 */
export const subscriptionLink = (wsUri) => {
  // Create instance of subscription client to use WebSocket client for GraphQL subscriptions
  const wsClient = new SubscriptionClient(wsUri, {
    reconnect: true, // automatic reconnect in case of connection error
    timeout: 30000, //  this parameter is ignored if the server does not send keep-alive messages
    lazy: true, // connects only when first subscription created, and delay the socket initialization
    // When connectionParams is a function, it gets evaluated before each connection.
    connectionParams: () => {
      const token = localStorage.getItem('msToken');
      return {
        Authorization: `Bearer ${token}`,
      };
    },
    // Handle connection errors
    connectionCallback: (error) => {
      if (error) {
        console.error(`ws error: ${error}`);
        appInsights.trackException({ exception: error });
      }
      // TODO: implement when server sends error messages
      // if (error && error.message === 'error-message-from-server') {
      //   wsClient.close();
      // }
    },
  });

  // Set subscription operation name to make network request easier to identify
  // wsClient.request = options => {
  //   wsClient.url = `${wsUri}/subscription/${options.operationName}`;
  // };

  return new WebSocketLink(wsClient);
};
