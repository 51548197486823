import { Alert, Button, Typography } from 'bluematter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Route, Switch, useLocation } from 'react-router-dom';
import './App.css';
import AppRedirects from './AppRedirects';
import Authorize from './components/auth/authorize/index';
import CheckGraphPermissions from './components/auth/CheckGraphPermissions';
import { ROLES } from './components/auth/roles';
import Navbar from './components/navbar/Navbar';
import ProfileSidebar from './components/sidebar/profileSidebar/ProfileSidebar';
import messages from './i18n/messages';
import {
  browserLanguage,
  browserLanguageCode,
} from './utils/language/getBrowserLanguage';
import pageTitle from './utils/string/pageTitle/pageTitle';
import pathName from './utils/string/pathName/pathName';
import Admin from './views/admin/Admin';
import PageNotFound from './views/pageNotFound/PageNotFound';

const App = ({ data }) => {
  const [hasServiceWorkerUpdated, setHasServiceWorkerUpdated] = useState(false);
  const [isDrawerToggleOn, setIsDrawerToggleOn] = useState(false);
  const { pathname } = useLocation();

  moment.locale(browserLanguage);

  useEffect(() => {
    const checkServiceWorker = () => {
      if (window.serviceWorkerUpdated) setHasServiceWorkerUpdated(true);
    };

    // Check for SW updates once after 6 sec
    const timeoutId = setTimeout(checkServiceWorker, 6000);
    // Check repeatedly for SW updates every 5 min
    const intervalId = setInterval(checkServiceWorker, 300000);

    return () => {
      // Clear timeout and interval
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    moment.locale(data?.currentUser?.preferredLanguage);
  }, [data?.currentUser?.preferredLanguage]);

  const handleServiceWorkerUpdate = () => {
    setHasServiceWorkerUpdated(false);

    window.serviceWorkerUpdated = false;
    window.location.reload();
  };

  const handleProfileSidebarToggle = () => {
    setIsDrawerToggleOn((prevState) => !prevState);
  };

  const platform = () => {
    return navigator.platform.match(/(Mac|iPhone|iPad)/i) ? 'apple' : 'windows';
  };

  const bodyClass = pathName(pathname) + ' ' + platform();
  const title = pageTitle(pathname);

  return (
    <IntlProvider
      locale={browserLanguageCode}
      messages={messages[browserLanguageCode]}
      defaultLocale="en"
      onError={(err) => {
        if (err.code === 'MISSING_TRANSLATION') {
          console.warn('Missing translation', err.message);
          return;
        }
        throw err;
      }}>
      <>
        <Helmet>
          <html lang={browserLanguage} />
          <title>{title}</title>
          <body className={bodyClass} />
        </Helmet>
        <Authorize roles={[ROLES.USER]}>
          {({ access }) =>
            access && (
              <CheckGraphPermissions>
                {hasServiceWorkerUpdated && (
                  <Alert type="info" noMargin>
                    <Typography noMargin>
                      <FormattedMessage
                        id="serviceWorker.update"
                        defaultMessage="A new update is available"
                      />
                    </Typography>
                    <Button
                      color="primary"
                      variant="solid"
                      onClick={handleServiceWorkerUpdate}>
                      <FormattedMessage
                        id="serviceWorker.updateButton"
                        defaultMessage="Update"
                      />
                    </Button>
                  </Alert>
                )}
                <Navbar
                  handleProfileSidebarToggle={handleProfileSidebarToggle}
                  isDrawerToggleOn={isDrawerToggleOn}
                />
                <Switch>
                  <Route exact path="/" component={AppRedirects} />
                  <Route
                    path="/admin"
                    render={({ history }) => (
                      <Admin
                        history={history}
                        isDrawerToggleOn={isDrawerToggleOn}
                      />
                    )}
                  />
                  <Route component={PageNotFound} />
                </Switch>
                <ProfileSidebar
                  className="sidebar--right"
                  isSidebarToggleOn={isDrawerToggleOn}
                />
              </CheckGraphPermissions>
            )
          }
        </Authorize>
      </>
    </IntlProvider>
  );
};

export default App;
