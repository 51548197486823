import en from './lang/en.json';
import da from './lang/da.json';
import fi from './lang/fi.json';
import nb from './lang/nb.json';
import sv from './lang/sv.json';
import nl from './lang/nl.json';
import de from './lang/de.json';
import es from './lang/es.json';

const messages = { en, da, nb, sv, fi, nl, de, es };

export default messages;
