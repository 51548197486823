import { Typography } from 'bluematter';
import PropTypes from 'prop-types';
import React from 'react';
import { appInsights } from '../../Root';
import Main from '../main/Main';

/**
 * @name ErrorBoundary
 * @description The ErrorBoundary catch JS errors anywhere in their child component tree,
 * log those errors, and display a fallback UI instead of the component tree that crashed.
 *
 * @class ErrorBoundary
 * @extends {React.Component}
 */
class ErrorBoundary extends React.Component {
  state = { hasError: false, error: '', info: '' };

  static propTypes = {
    children: PropTypes.object,
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: error, info: info });

    appInsights.trackException({ exception: error, info: info });
  }

  render() {
    const { children } = this.props;
    const { hasError, error, info } = this.state;

    if (hasError) {
      return (
        <Main style={{ height: '100vh' }}>
          <div
            className="error-boundary"
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <div className="view">
              <Typography variant="h3">Something went wrong.</Typography>
              <Typography variant="h4">{error && error.toString()}</Typography>
              <Typography>{info.componentStack}</Typography>
            </div>
          </div>
        </Main>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
