const hostId = window.location.hostname.split('.').slice(-2).join('.');

const hostSettings = {
  localhost: {
    id: 'local',
    applicationId: 'af7056f7-928e-4001-8178-274ec1b3862f',
    aadiBaseUrl: 'http://localhost:5000/api',
    debug: true,
    graphHttpAddress: 'http://localhost:4000/graphql',
    instrumentationKey: 'd1910b4b-63d9-4811-b253-4461375b8cbd',
  },
  'goaskcody.com': {
    id: 'us',
    applicationId: '1fa56701-1ef6-40cc-9bf9-dff4b0e0af78',
    aadiBaseUrl: 'https://aadi.goaskcody.com/api',
    graphHost: 'omnibus.goaskcody.com',
    instrumentationKey: '4c645a6e-1c89-4e44-ad85-6a888e4f0d8b',
  },
  'onaskcody.com': {
    id: 'eu',
    applicationId: '5a242508-7321-4320-a66a-bd4d65b102db',
    aadiBaseUrl: 'https://aadi.onaskcody.com/api',
    graphHost: 'omnibus.onaskcody.com',
    instrumentationKey: '562d6770-6573-4bec-bc07-9faf1181e398',
  },
  'testaskcody.com': {
    id: 'test',
    applicationId: '2d3ad281-ead2-44f6-b1e8-87740a883d17',
    aadiBaseUrl: 'https://aadi.testaskcody.com/api',
    graphHost: 'omnibus.testaskcody.com',
    instrumentationKey: '26ad4f89-a69b-4b80-8f83-252af1b54644',
  },
};

const settings = hostSettings[hostId];

settings.aiCloudRole = 'aadi-frontend';
settings.isProduction = settings.id === 'eu' || settings.id === 'us';

/** Set shared settings */
settings.loginScopes = ['User.Read', 'offline_access'];

/** Set derived settings */
settings.debug = settings.debug || false;

/** Can be used to connect to all our API */
settings.defaultScopes = [`api://${settings.applicationId}/access_as_user`];

settings.graphHttpAddress =
  settings.graphHttpAddress || `https://${settings.graphHost}/graphql`;

settings.graphWsAddress = `wss://${settings.graphHost}/api`;

const baseUrl = `${window.location.protocol}//${window.location.host}`;

settings.postLogoutRedirectUri = `${baseUrl}/`;
settings.redirectUri = `${baseUrl}/authorize`;

export default settings;
