import { validate } from '../../validation/validation';
/**
 * Returns a page title from a url pathname
 *
 * @export
 * @param {string} pathName
 * @param {string} [prefix='']
 * @param {string} [defaultPageTitle='AskCody AADI']
 * @returns {string}
 */
export default function pageTitle(
  pathName,
  prefix = '',
  defaultPageTitle = 'AskCody AADI'
) {
  // Split pathName into an array of path segments using forward slash separator
  const pathSegments = pathName.toLowerCase().split('/').filter(Boolean);

  let pageTitle = '';

  // If we have no path segments i.e. if pathName = / then return prefix or default page title
  // else if we have one path segment return capitalized path segment
  // else if we have more than 1 path segments loop through and format the path segments
  if (!pathSegments.length) {
    return prefix || defaultPageTitle;
  } else if (pathSegments.length === 1) {
    pageTitle = capitalizeWord(pathSegments[0]);
  } else if (pathSegments.length > 1) {
    let arr = [];

    // Loop through path segments
    pathSegments.forEach((item) => {
      // If path segment doesn't include a dash
      // then add the capitalized word to arr
      if (!item.includes('-')) {
        arr = [...arr, capitalizeWord(item)];
      }

      // If path segment includes a dash and is not an uuid
      if (item.includes('-') && !validate.uuid.test(item)) {
        let arr2 = [];
        // Split item into an array using dash separator
        const pathSegmentsWithDashes = item
          .toLowerCase()
          .split('-')
          .filter(Boolean);

        // Loop through path segments with dashes and add capitalized words to arr2
        pathSegmentsWithDashes.forEach((subItem) => {
          arr2 = [...arr2, capitalizeWord(subItem)];
        });

        // Add arr2 words to arr
        arr = [...arr, arr2.join(' ')];
      }
    });

    // Join array with a pipe between each word
    pageTitle = arr.join(' | ');
  }

  // Return the page title with or without prefix
  return replaceSymbols(`${prefix ? `${prefix} | ` : ''}${pageTitle}`);
}

/**
 * Returns a string with plus and dash replaced
 *
 * @param {string} value
 * @returns {string}
 */
function replaceSymbols(value) {
  return value
    .replace(/-plus/i, '+')
    .replace(/ plus/i, '+')
    .replace(/plus/i, '+')
    .replace(/-/g, ' ');
}

/**
 * Returns capitalized word
 *
 * @param {string} value
 * @returns {string}
 */
function capitalizeWord(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
