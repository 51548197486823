import { Spinner } from 'bluematter';
import PropTypes from 'prop-types';
import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import Authorize from '../../components/auth/authorize/Authorize';
import { ROLES } from '../../components/auth/roles';
import Main from '../../components/main/Main';
import AdminSidebar from './../admin/adminSidebar/AdminSidebar';
import Redirects from './redirects/Redirects';

const Sync = lazy(() => import('./sync/Sync'));

const AzureAdSyncLog = lazy(() => import('./sync/log/AzureAdSyncLog'));

const Troubleshooting = lazy(() =>
  import('./sync/troubleshooting/Troubleshooting')
);

const Admin = ({ isDrawerToggleOn, history }) => {
  function handleReject(access) {
    if (!access) history.goBack();
  }

  return (
    <>
      <AdminSidebar />
      <Main isDrawerToggleOn={isDrawerToggleOn} className="admin">
        <Route exact path="/admin" component={Redirects} />

        <Authorize roles={[ROLES.OWNER]} onReject={handleReject}>
          <>
            <Route
              path="/admin/sync"
              render={() => (
                <Suspense
                  fallback={
                    <Spinner isFullscreen isFixed usePortal theme="light" />
                  }>
                  <Sync />
                </Suspense>
              )}
            />
            <Route
              path="/admin/azure-ad-sync-log"
              render={({ match }) => (
                <Suspense
                  fallback={
                    <Spinner isFullscreen isFixed usePortal theme="light" />
                  }>
                  <AzureAdSyncLog match={match} />
                </Suspense>
              )}
            />
            <Route
              path="/admin/azure-ad-sync-troubleshooting"
              render={({ match }) => (
                <Suspense
                  fallback={
                    <Spinner isFullscreen isFixed usePortal theme="light" />
                  }>
                  <Troubleshooting match={match} />
                </Suspense>
              )}
            />
          </>
        </Authorize>
      </Main>
    </>
  );
};

Admin.propTypes = {
  history: PropTypes.object.isRequired,
  isDrawerToggleOn: PropTypes.bool.isRequired,
};

export default Admin;
